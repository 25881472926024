import React from 'react';
import styles from './contactus.module.css';
import Header2 from '../../components/header2';
import { authData } from '../../components/getAuth';
import axios from 'axios';
import { useState } from 'react';
import { toast } from 'react-smart-toaster';
import { Mail, Map, Phone } from 'react-feather';
import Header1 from '../../components/header1';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import Loader from '../../../admin/components/Loader';

let schema = yup.object().shape({
    first_name: yup.string().required('This field is Required'),
    last_name: yup.string().required('This field is Required'),
    email: yup.string().required('This field is Required'),
    phone: yup.string().required('This field is Required'),
    message: yup.string().required('This field is Required'),
});

const ContactUs = () => {
    const auth = authData();
    const [loading, setLoading] = useState(false);
    const { register, handleSubmit, watch,  formState: { errors }, reset } = useForm({
        resolver: yupResolver(schema), mode: 'onChange'
    });
    
    const onSubmit = (data) =>{
        setLoading(true);

        axios.post(process.env.REACT_APP_API_URL + 'public/conatact-us', data).then(response => {
            setLoading(false);
            reset();
            toast.success(response.data.message);
        }).catch(error => {
            setLoading(false);
            if(error && error.response && error.response.data && error.response.data.message){
                toast.error(error.response.data.message);
            }else{
                toast.error("Error occured. Please try again");
            }
        });
    }

    return (<React.Fragment>
        {loading && <Loader /> }
        {auth && <Header2 />}
        {!auth && <Header1 />}

        <div className={`${styles.Container}`}>
            <div className={`${styles.ContactFromRow}`}>
                <div className={`${styles.ContactFormBigSec}`}>
                    <h2 className={`${styles.Heading2}`}>Contact Us</h2>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className={`${styles.FormFieldHalf}`}>
                            <label>First Name <span>*</span></label>
                            <input type="text" {...register("first_name")} />
                            {errors.first_name && !watch().first_name && <span className={`${styles.ErrorM}`}>{errors.first_name.message}</span>}
                        </div>
                        <div className={`${styles.FormFieldHalf}`}>
                            <label>Last Name<span>*</span></label>
                            <input type="text" {...register("last_name")} />
                            {errors.last_name && !watch().last_name && <span className={`${styles.ErrorM}`}>{errors.last_name.message}</span>}
                        </div>
                        <div className={`${styles.FormFieldHalf}`}>
                            <label>Phone Number<span>*</span></label>
                            <input type="text" {...register("phone")} />
                            {errors.email && !watch().email && <span className={`${styles.ErrorM}`}>{errors.email.message}</span>}
                        </div>
                        <div className={`${styles.FormFieldHalf}`}>
                            <label>Email<span>*</span></label>
                            <input type="text" {...register("email")} />
                            {errors.phone && !watch().phone && <span className={`${styles.ErrorM}`}>{errors.phone.message}</span>}
                        </div>
                        <div className={`${styles.FormFieldFull}`}>
                            <label>Message<span>*</span></label>
                            <textarea {...register("message")} ></textarea>
                            {errors.message && !watch().message && <span className={`${styles.ErrorM}`}>{errors.message.message}</span>}
                        </div>
                        <div className={`${styles.FormFieldHalf}`}>
                            <button className={`${styles.SubButton}`} type="submit">Submit</button>
                        </div>
                    </form>
                </div>
                <div className={`${styles.ContactFormSmallSec}`}>
                    <h1 className={`${styles.Heading}`}>ADDRESS</h1>
                    <div className={`${styles.AddressArea}`}>
                        <p className={`${styles.Addstitle}`}>Ace Pariksha</p>
                        <div className={`${styles.Addstitle2}`}><Map />Plot 38 & 50, Dwarkanagar, Rajendra Nagar, Hyderabad - 500086</div>
                        <div className={`${styles.Addstitle2}`}><Phone />07853 607006</div>
                        <div className={`${styles.Addstitle2}`}><Mail />aceparikshacontact@gmail.com</div>
                    </div>
                </div>
            </div>
        </div>

    </React.Fragment>);
}

export default ContactUs;