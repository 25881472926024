import { Navigate } from "react-router-dom";
const withStudentAuth = (WrappedComponent) => {
  return (props) => {
    if (typeof window !== "undefined") {
      const authDataAce = localStorage.getItem("authDataAce");
      if(authDataAce){
        let authDataAceArr= JSON.parse(authDataAce);
        if(authDataAceArr?.role === 'Student'){
          return <WrappedComponent {...props} />;
        }else if(authDataAceArr?.role === 'Mentor'){
          return <Navigate to={'/mentor/dashboard'} />;
        }else if(authDataAceArr?.role === 'Admin'){
          return <Navigate to={'/admin/user/list'} />;
        }else{
          return <Navigate to={'/home'} />;
        }
      }else{
        return <Navigate to={'/login'} />
      }
    }
    return null;
  };
};

export default withStudentAuth;