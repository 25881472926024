import React, { useEffect, useState } from 'react';
import styles from './step1.module.css';
import Header2 from '../../components/header2';
import withMentorAuth from '../../components/withMentorAuth';
import { Controller, useForm } from 'react-hook-form';
import axios from 'axios';
import Select from "react-select";
import { useNavigate, useParams } from 'react-router-dom';
import { authData } from '../../components/getAuth';
import { toast } from 'react-smart-toaster';

const CreateTestStep1 = () => {
  const auth = authData();
  const navigate = useNavigate();
  let { id } = useParams();
  const isAddMode = !id;
  const [boardList, setBoardList] = useState([]);
  const [classList, setClassList] = useState([]);
  const [subjectList, setSubjectList] = useState([]);
  const [allSubjectList, setAllSubjectList] = useState([]);
  const [topicAllList, setTopicAllList] = useState([]);
  const [topicList, setTopicList] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const [selectedBoard, setSelectedBoard] = useState('');
  const [selectedClass, setSelectedClass] = useState('');
  const [selectedSubject, setSelectedSubject] = useState('');
  const { handleSubmit, register, formState: { errors }, control, setValue } = useForm();
  const [ testData, setTestData] = useState(null);

  useEffect(()=>{
    if(!isAddMode){
      axios.get(process.env.REACT_APP_API_URL + 'mentor/test/'+id, {
        headers: { 'x-access-token': auth?.token }
      }).then(response => {
        setTestData(response.data.data);
      }).catch(error => {
        if (error && error.response && error.response.data && error.response.data.message) {
          toast.error(error.response.data.message);
        } else {
          toast.error("Error occured. Please try again");
        }
      });
    }
  },[isAddMode,id,auth?.token]);

  useEffect(()=>{
    if(testData){
      boardList.forEach(item=>{
        if(item?.value === testData?.board_id){
          setValue('board_id', item);
        }
      });
    }
  },[testData,setValue,boardList]);

  useEffect(()=>{
    if(testData){
      classList.forEach(item=>{
        if(item?.value === testData?.class_id){
          setValue('class_id', item);
        }
      });
    }
  },[testData,setValue,classList]);

  useEffect(()=>{
    if(testData){
      allSubjectList.forEach(item=>{
        if(item?._id === testData?.subject_id){
          setValue('subject_id', { value: item._id, label: item.title });
        }
      });
    }
  },[testData,setValue,allSubjectList]);

  useEffect(()=>{
    if(testData){
      if(testData?.topic_id){
        topicAllList.forEach(item=>{
          if(item?.value === testData?.topic_id){
            setValue('topic_id', item);
          }
        });
      }else{
        setValue('topic_id', {value:'all',label:'All Topics'});
      }
    }
  },[testData,setValue,topicAllList]);

  useEffect(()=>{
    if(testData){
      if(testData?.group_id){
        groupList.forEach(item=>{
          if(item?.value === testData?.group_id){
            setValue('group_id', item);
          }
        });
      }
    }
  },[testData,setValue,groupList]);

  useEffect(()=>{
    if(testData){
      setValue('title',testData?.title);
      setValue('description',testData?.description);
      setValue('level',{ label: testData?.level, value: testData?.level });
    }
  },[testData,setValue]);

  useEffect(() => {
    axios.get(process.env.REACT_APP_API_URL + 'public/boards').then(response => {
      let boardListTemp = response.data.data.map(i => { return { value: i._id, label: i.title }; });
      setBoardList(boardListTemp);
    }).catch(error => {
      if (error && error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Error occured. Please try again");
      }
    });
  }, []);

  useEffect(() => {
    axios.get(process.env.REACT_APP_API_URL + 'public/classes').then(response => {
      let classListTemp = response.data.data.map(i => { return { value: i._id, label: i.title }; });
      setClassList(classListTemp);
    }).catch(error => {
      if (error && error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Error occured. Please try again");
      }
    });
  }, []);

  useEffect(() => {
    axios.get(process.env.REACT_APP_API_URL + 'public/subjects').then(response => {
      let subjectListTemp = response.data.data;
      setAllSubjectList(subjectListTemp);
    }).catch(error => {
      if (error && error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Error occured. Please try again");
      }
    });
  }, []);

  useEffect(() => {
    axios.get(process.env.REACT_APP_API_URL + 'public/topics').then(response => {
      let topicListTemp = response.data.data.map(i => { return { value: i._id, topic_index: i.topic_index, label: 'Chapter ' + i.topic_index + ': ' + i.title, board_id: i?.board_id, class_id: i?.class_id, subject_id: i?.subject_id }; });
      setTopicAllList(topicListTemp);
    }).catch(error => {
      if (error && error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Error occured. Please try again");
      }
    });
  }, []);

  useEffect(() => {
    axios.get(process.env.REACT_APP_API_URL + 'public/group-list').then(response => {
      let groupListTemp = response.data.data.map(i => { return { value: i._id, label: i.title+' ('+i.classes+')' }; });
      setGroupList(groupListTemp);
    }).catch(error => {
      if (error && error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Error occured. Please try again");
      }
    });
  }, []);

  useEffect(() => {
    if (selectedBoard !== '' && selectedClass !== '') {
      let subjectListTemp = allSubjectList.filter(i => (i.boards.indexOf(selectedBoard) > -1 && i.classes.indexOf(selectedClass) > -1)).map(i => { return { value: i._id, label: i.title }; });
      setSubjectList(subjectListTemp);
    }
    setValue('subject_id', null);
  }, [selectedBoard, selectedClass, allSubjectList, setValue]);

  useEffect(() => {
    if (selectedBoard !== '' && selectedClass !== '' && selectedSubject !== '') {
      let topicListTemp = topicAllList.sort((a, b) => a.topic_index - b.topic_index).filter(i => (i.board_id === selectedBoard && i.class_id === selectedClass && i.subject_id === selectedSubject));
      topicListTemp = [{value:'all',label:'All Topics'}].concat(topicListTemp);
      setTopicList(topicListTemp);
    }
    setValue('topic_id', null);
  }, [selectedBoard, selectedClass, selectedSubject, topicAllList, setValue]);

  const onSubmit = (data) => {
    if (data?.board_id?.value) {
      data = { ...data, board_id: data?.board_id?.value };
    }
    if (data?.class_id?.value) {
      data = { ...data, class_id: data?.class_id?.value };
    }
    if (data?.subject_id?.value) {
      data = { ...data, subject_id: data?.subject_id?.value };
    }
    if (data?.topic_id?.value) {
      data = { ...data, topic_id: data?.topic_id?.value };
    }
    if (data?.group_id?.value) {
      data = { ...data, group_id: data?.group_id?.value };
    }
    if (data?.level?.value) {
      data = { ...data, level: data?.level?.value };
    }
    data = { ...data, status: 0 };
    if(data?.topic_id === 'all'){
      delete data.topic_id;
    }
    if(isAddMode){
      axios.post(process.env.REACT_APP_API_URL + 'mentor/create-test', data, {
        headers: { 'x-access-token': auth?.token }
      }).then(response => {
        let testData = response.data.data;
        navigate('/mentor/create-test/step-2/' + testData?._id);
      }).catch(error => {
        if (error && error.response && error.response.data && error.response.data.message) {
          toast.error(error.response.data.message);
        } else {
          toast.error("Error occured. Please try again");
        }
      });
    }else{
      axios.put(process.env.REACT_APP_API_URL + 'mentor/update-test/' + id, data, {
        headers: { 'x-access-token': auth?.token }
      }).then(response => {
        navigate('/mentor/create-test/step-2/' + id);
      }).catch(error => {
        if (error && error.response && error.response.data && error.response.data.message) {
          toast.error(error.response.data.message);
        } else {
          toast.error("Error occured. Please try again");
        }
      });
    }
    
  }

  return (<React.Fragment>
    <div className={`${styles.background_Color}`}>
      <Header2 />

      <div className={`${styles.Container}`}>
        <div className={`${styles.create_AccomplishmentSec}`}>
          <p>Create test</p>
        </div>
        <div className={`${styles.FormBoxArea}`}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={`${styles.FormField}`}>
              <label className={`${styles.FormLabel}`}>Name</label>
              <input type="text" placeholder="Name" className={`${styles.FormInput}`} {...register("title",{ required: 'This field is required' })} />
              {errors?.title && <p className={`${styles.login_ErrorM}`}>{errors?.title?.message}</p>}
            </div>
            <div className={`${styles.FormField}`}>
              <label className={`${styles.FormLabel}`}>Description</label>
              <input type="text" placeholder="Description" className={`${styles.FormInput}`} {...register("description", { required: 'This field is required' })} />
              {errors?.description && <p className={`${styles.login_ErrorM}`}>{errors?.description?.message}</p>}
            </div>
            <div className={`${styles.FormRow}`}>
              <div className={`${styles.FormRowHalf}`}>
                <div className={`${styles.FormField}`}>
                  <label className={`${styles.FormLabel}`}>Board</label>
                  <Controller control={control} className={`${styles.FormInput}`} options={boardList} {...register('board_id', { required: 'This field is required' })} render={({ field }) => (
                    <Select {...field} onChange={(e) => { field.onChange(e); setSelectedBoard(e?.value); }} options={boardList} />
                  )} />
                  {errors?.board_id && <p className={`${styles.login_ErrorM}`}>{errors?.board_id?.message}</p>}
                </div>
              </div>
              <div className={`${styles.FormRowHalf}`}>
                <div className={`${styles.FormField}`}>
                  <label className={`${styles.FormLabel}`}>Class</label>
                  <Controller control={control} className={`${styles.FormInput}`} options={classList} {...register('class_id', { required: 'This field is required' })} render={({ field }) => (
                    <Select {...field} onChange={(e) => { field.onChange(e); setSelectedClass(e?.value); }} options={classList} />
                  )} />
                  {errors?.class_id && <p className={`${styles.login_ErrorM}`}>{errors?.class_id?.message}</p>}
                </div>
              </div>
            </div>
            <div className={`${styles.FormRow}`}>
              <div className={`${styles.FormRowHalf}`}>
                <div className={`${styles.FormField}`}>
                  <label className={`${styles.FormLabel}`}>Subject</label>
                  <Controller control={control} className={`${styles.FormInput}`} options={subjectList} {...register('subject_id', { required: 'This field is required' })} render={({ field }) => (
                    <Select {...field} onChange={(e) => { field.onChange(e); setSelectedSubject(e?.value); }} options={subjectList} />
                  )} />
                  {errors?.subject_id && <p className={`${styles.login_ErrorM}`}>{errors?.subject_id?.message}</p>}
                </div>
              </div>
              <div className={`${styles.FormRowHalf}`}>
                <div className={`${styles.FormField}`}>
                  <label className={`${styles.FormLabel}`}>Level</label>
                  <Controller control={control} className={`${styles.FormInput}`} options={[{ label: 'Easy', value: 'Easy' }, { label: 'Medium', value: 'Medium' }, { label: 'Hard', value: 'Hard' }]} {...register('level', { required: 'This field is required' })} render={({ field }) => (
                    <Select {...field} options={[{ label: 'Easy', value: 'Easy' }, { label: 'Medium', value: 'Medium' }, { label: 'Hard', value: 'Hard' }]} />
                  )} />
                  {errors?.level && <p className={`${styles.login_ErrorM}`}>{errors?.level?.message}</p>}
                </div>
              </div>
            </div>
            <div className={`${styles.FormRow}`}>
              <div className={`${styles.FormRowHalf}`}>
                <div className={`${styles.FormField}`}>
                <label className={`${styles.FormLabel}`}>Topic</label>
                  <Controller control={control} className={`${styles.FormInput}`} options={topicList} {...register('topic_id', { required: 'This field is required' })} render={({ field }) => (
                    <Select {...field} options={topicList} />
                  )} />
                  {errors?.topic_id && <p className={`${styles.login_ErrorM}`}>{errors?.topic_id?.message}</p>}
                </div>
              </div>
              <div className={`${styles.FormRowHalf}`}>
                <div className={`${styles.FormField}`}>
                  <label className={`${styles.FormLabel}`}>Group</label>
                  <Controller control={control} className={`${styles.FormInput}`} options={groupList} {...register('group_id')} render={({ field }) => (
                    <Select {...field} options={groupList} />
                  )} />
                </div>
              </div>
            </div>
            <div className={`${styles.ButtonSec}`}>
              <button type='submit' className={`${styles.SaveBU}`}>Next</button>
            </div>
          </form>
        </div>
      </div>
    </div>

  </React.Fragment>)
}

export default withMentorAuth(CreateTestStep1);