import React, { useState } from 'react';
import styles from './login.module.css';
import { useNavigate } from "react-router-dom";
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { toast } from 'react-smart-toaster';

const Login = () => {
  const navigate = useNavigate();
  const [lError, setlError] = useState('')

  const {  register, handleSubmit, formState:{errors} } = useForm();
  const onSubmit = (data) => {
    setlError('');
    axios.post(process.env.REACT_APP_API_URL + 'login', data).then(response => {
      localStorage.setItem("authDataAce",JSON.stringify(response.data.data));
      navigate('/admin/user/list');
    }).catch(error =>{
      if(error && error.response && error.response.data && error.response.data.message){
        setlError(error.response.data.message);
      }else{
        toast.error("Error occured. Please try again");
      }
    });
  }
  
  return (<React.Fragment>
    <div className={`${styles.login_MainDiv}`}>
      <div className={`${styles.login_BannerSec}`}>
        <img src='/static/img/Get_started_page_image.jpg' alt='' />
      </div>
      <div className={`${styles.login_FormSec}`}>
        <div className={`${styles.login_LohinForm}`}>
          <h2 className={`${styles.login_FormTitle} ${styles.Heading}`}>Admin Login</h2>
          <div className={`${styles.tabs}`}>
              <form onSubmit={handleSubmit(onSubmit)}>
                {(lError !== '') && <p>{lError}</p>}
                <div className={`${styles.login_FormRow}`}>
                  <div className={`${styles.login_FormFieldHalf}`}>
                    <span className={`${styles.details}`}>Email</span>
                    <input type="text" className={`${styles.login_FormControl}`} placeholder="Email" {...register("email", {
                      required: "Email is required",
                      validate: {
                        matchPattern: (v) =>
                        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || "Email address must be a valid address",
                      },
                    })} />
                    {errors?.email && <p>{errors?.email?.message}</p>}
                  </div>
                  <div className={`${styles.login_FormFieldHalf}`}>
                    <span className={`${styles.details}`}>Password</span>
                    <input type="password" className={`${styles.login_FormControl}`} placeholder="Password"{...register("password", { required: "Password is required" })} />
                    {errors?.password && <p>{errors?.password?.message}</p>}
                  </div>
                  {/*<a href=""><p className={`${styles.Forgot_PasswordArea}`}>Forgot Password ?</p></a>*/}
                  <div className={`${styles.buttonGroup}`}>
                    <input type="submit" value="Login" className={`${styles.loginButton}`} />
                  </div>
                </div>
              </form>
          </div>
        </div>
      </div>
    </div>
  </React.Fragment>)
}

export default Login;