import React, { useEffect, useState } from 'react';
import styles from './testevalute.module.css';
import { Link, useParams } from 'react-router-dom';
import Header2 from '../../components/header2';
import withMentorAuth from '../../components/withMentorAuth';
import axios from 'axios';
import { toast } from 'react-smart-toaster';
import { authData } from '../../components/getAuth';
import * as moment from 'moment';
import { Button, TextField } from '@mui/material';
import { X } from 'react-feather';
import DataTable from 'react-data-table-component';

const TestEvalute = () => {
  const auth = authData();
  const { id } = useParams();
  const [allTestList, setAllTestList] = useState([]);
  const [filteredTestList, setFilteredTestList] = useState([]);
  const [filterText, setFilterText] = useState('');
  const [testData, setTestData] = useState(null);

  useEffect(()=>{
    function getResults() {
      axios(process.env.REACT_APP_API_URL + 'mentor/evalute/'+id, {
        headers: { 'x-access-token': auth?.token }
      }).then(response => {
        setAllTestList(response.data.data);
      }).catch(error => {
        if (error && error.response && error.response.data && error.response.data.message) {
          toast.error(error.response.data.message);
        } else {
          toast.error("Error occured. Please try again");
        }
      });
    }

    if (auth?.token && id) {
      getResults();
    }

  },[id,auth?.token]);

  useEffect(()=>{
    function getTest() {
      axios(process.env.REACT_APP_API_URL + 'mentor/test/'+id, {
        headers: { 'x-access-token': auth?.token }
      }).then(response => {
        setTestData(response.data.data);
      }).catch(error => {
        if (error && error.response && error.response.data && error.response.data.message) {
          toast.error(error.response.data.message);
        } else {
          toast.error("Error occured. Please try again");
        }
      });
    }

    if (auth?.token && id) {
      getTest();
    }

  },[id,auth?.token]);

  const columns = [
    {
      name: 'Student',
      selector: row => row?.student?.first_name+' '+row?.student?.last_name,
      sortable: true
    },
    {
      name: 'Attempted On',
      selector: row => moment(row?.createdAt).format('DD/MM/YYYY'),
      sortable: true
    },
    {
      name: 'No Of Question',
      selector: row => row.answers?.filter(i=> i?.is_evalute === 0).length,
      sortable: true,
    },
    {
      name: '',
      cell: tableProps  => ( <div>
        <Link style={{fontSize: '12px', fontWeight: 600, color: '#fff', padding: '8px', borderRadius: '5px',  background: '#ff8414'}} to={'/mentor/student-test-evalute/'+tableProps?._id}>Evalute</Link>
      </div> ),
      sortable: false
    }
  ];

  const customStyles = {
    headCells: {
        style: {
          fontSize: '16px',
          color: '#4B4B4B',
          fontWeight: 600
        },
    },
    cells: {
        style: {
          fontSize: '14px',
          color: '#191D63',
          fontWeight: 500
        },
    }
  };

  const paginationComponentOptions = {
    noRowsPerPage: true
  };
  
  useEffect(()=>{
    let filteredData = allTestList;

    if(filterText !== ''){
      filteredData = filteredData.filter((item) => {
        return (item?.student?.first_name+' '+item?.student?.last_name).toLowerCase().includes(filterText.toLowerCase()) || (item?.student?.first_name+' '+item?.student?.last_name).toLowerCase().includes(filterText.toLowerCase());
     });
    }
    setFilteredTestList(filteredData);
  },[filterText,allTestList]);

  return (<React.Fragment>
    <div className={`${styles.background_Color}`}>
      <Header2 />

      <div className={`${styles.Container}`}>
        <div className={`${styles.my_DashboardMainArea}`}>
          <div className={`${styles.my_DashboardNam}`}>
            <p className={`${styles.my_DashboarHeading}`}>Hi {auth?.first_name},</p>
            <p className={`${styles.my_DashboarSubHeading}`}>Test Evaluation</p>
          </div>
        </div>
      </div>

      <div className={`${styles.Container}`}>
        <div className={`${styles.Row}`}>

          <div className={`${styles.BigDiv_Sec}`}>

            <div className={`${styles.text_ButtonGrop}`}>
              <div className={`${styles.BigDiv_Sec_UnderSmL}`}>
                <div className={`${styles.UnderSmLContGroup}`}>
                  <p className={`${styles.UnderSmLbigTitle}`}>{testData?.subject?.title}: {testData?.title}</p>
                </div>
              </div>
            </div>

            <div className={`${styles.table_Sec}`}>
              <div className='TableFilterHead'>
                <div className="DataTableSearch">
                  <TextField id="search" type="text" placeholder="Search by keyword" aria-label="Search Input" value={filterText} onChange={(e) => setFilterText(e.target.value)} />
                  {filterText && <Button type="button" className="CloseBU" onClick={(e) => setFilterText('')}><X/></Button>}
                </div>
              </div>
              <DataTable
                className={`${styles.table}`}
                columns={columns}
                data={filteredTestList}
                pagination
                customStyles={customStyles}
                paginationComponentOptions={paginationComponentOptions}
                noDataComponent={<div className={`${styles.noData_FoundSec}`}>
                <div className={`${styles.noData_Found_SecUnder}`}>
                  <img src='/static/img/no-datafound.png' alt='' />
                  <p>No tests are available now</p>
                </div>
              </div>}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </React.Fragment>)
}

export default withMentorAuth(TestEvalute);