import React from 'react';
import styles from './header2.module.css';
import { Link, useNavigate } from "react-router-dom";
import { Button } from '@mui/material';

function Header2() {
    const navigate = useNavigate();
    
    const logout = ()=>{
        localStorage.removeItem('authDataAce');
        navigate('/login');
    }
    
    return (<div className={`${styles.Header}`}>
        <div className={`${styles.Container}`}>
            <div className={`${styles.HeadRow}`}>
                <img src="" className={`${styles.HeadLogo}`} alt='' />
                <div className={`${styles.Menu}`}>
                    <ul>
                        <li><Link to={'/mentor/dashboard'}>Dashboard</Link></li>
                        <li><Link to={'/mentor/groups'}>Groups</Link></li>
                        <li><Link to={'/mentor/tests'}>Tests</Link></li>
                        <li><Link to={'/mentor/my-profile'}>Profile</Link></li>
                        {/*<li><Link to={'/'}>Settings</Link></li>*/}
                    </ul>
                    <Button className={`${styles.HeadLoginBU}`} onClick={logout}>Logout</Button>
                </div>
            </div>
        </div>
    </div>);
}

export default Header2;